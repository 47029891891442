
import { Component, Vue } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import FleetHeader from '@/modules/cars/modules/fleet/components/fleet-header.vue';
import FleetActions from '@/modules/cars/modules/fleet/components/fleet-actions.vue';
import FleetDensity from '@/modules/cars/modules/fleet/components/fleet-density.vue';
import FleetTable from '@/modules/cars/modules/fleet/components/fleet-table.vue';
import FleetScan from '@/modules/cars/modules/fleet/components/fleet-scan.vue';

@Component({
    components: {
        FleetTable,
        FleetDensity,
        FleetActions,
        PageWrapper,
        FleetHeader,
        FleetScan,
    },
})
export default class FleetDensityPage extends Vue {}
